import { Themes } from './types';

// eslint-disable-next-line import/prefer-default-export
export const themes: Themes = {
  default: {
    headerBg: { hex: '#ffffff', rgb: [255, 255, 255] },
    mainBg: { hex: '#dadff2', rgb: [218, 223, 242] },
    mainBgCenter: { hex: '#dadff2', rgb: [218, 223, 242] },
    border: { hex: '#b6b6b6', rgb: [182, 182, 182] },
    primary: { hex: '#182a5b', rgb: [24, 42, 91] },
    secondary: { hex: '#182a5b', rgb: [24, 42, 91] },
    darkBlue: { hex: '#383d51', rgb: [56, 61, 81] },
    textDarkBlue: { hex: '#544359', rgb: [84, 67, 89] },
    lightBlue: { hex: '#8d8f99', rgb: [141, 143, 153] },
    red: { hex: '#df3f42', rgb: [223, 63, 66] },
    orange: { hex: '#ffac4d', rgb: [255, 172, 77] },
    green: { hex: '#0b8f08', rgb: [11, 143, 8] },
    white: { hex: '#ffffff', rgb: [255, 255, 255] },
    whiteElement: { hex: '#ffffff', rgb: [255, 255, 255] },
    whiteItem: { hex: '#ffffff', rgb: [255, 255, 255] },
    blackElement: { hex: '#181717', rgb: [56, 61, 81] },
    darkOrange: { hex: '#2ea828', rgb: [46, 168, 40] }, // for deposit APY
    gray: { hex: '#707070', rgb: [112, 112, 112] },
    lightGray: { hex: '#eeeaf4', rgb: [238, 234, 244] },
    darkGray: { hex: '#a2a2a2', rgb: [162, 162, 162] },
    disabledGray: { hex: '#e0e0e2', rgb: [224, 224, 226] },
  },
  dark: {
    headerBg: { hex: '#171717', rgb: [0, 0, 0] },
    mainBg: { hex: '#2e2d43', rgb: [46, 45, 67] },
    mainBgCenter: { hex: '#2e2d43', rgb: [46, 45, 67] },
    border: { hex: '#565055', rgb: [86, 80, 85] },
    primary: { hex: '#e9981b', rgb: [233, 152, 27] },
    secondary: { hex: '#e9981b', rgb: [233, 152, 27] },
    darkBlue: { hex: '#181717', rgb: [24, 23, 23] },
    textDarkBlue: { hex: '#f1f1f3', rgb: [241, 241, 243] },
    lightBlue: { hex: '#8d8f99', rgb: [141, 143, 153] },
    red: { hex: '#df3f42', rgb: [223, 63, 66] },
    orange: { hex: '#ffac4d', rgb: [255, 172, 77] },
    green: { hex: '#0b8f08', rgb: [11, 143, 8] },
    white: { hex: '#f1f1f3', rgb: [241, 241, 243] },
    whiteElement: { hex: '#181717', rgb: [56, 61, 81] },
    blackElement: { hex: '#FFFFFF', rgb: [255, 255, 255] },
    whiteItem: { hex: '#372f47', rgb: [55, 47, 71] },
    darkOrange: { hex: '#2ea828', rgb: [46, 168, 40] }, // for deposit APY
    gray: { hex: '#707070', rgb: [112, 112, 112] },
    lightGray: { hex: '#a2a2a2', rgb: [162, 162, 162] },
    darkGray: { hex: '#565055', rgb: [86, 80, 85] },
    disabledGray: { hex: '#20202e', rgb: [32, 32, 46] },
  },
};
