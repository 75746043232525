import UNI from './UNI.svg';
import BPT from './BPT.svg';
import GUNI from './GUNI.svg';

interface SeparatorSymbol {
  [key: string]: string;
}

// eslint-disable-next-line import/prefer-default-export
export const images: SeparatorSymbol = {
  UNI,
  BPT,
  GUNI,
};
