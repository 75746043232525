/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer } from "ethers";
import { Provider } from "@ethersproject/providers";

import type { IRepayWithCollateral } from "../IRepayWithCollateral";

export class IRepayWithCollateral__factory {
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): IRepayWithCollateral {
    return new Contract(
      address,
      _abi,
      signerOrProvider
    ) as IRepayWithCollateral;
  }
}

const _abi = [
  {
    inputs: [
      {
        internalType: "address",
        name: "collateralAsset",
        type: "address",
      },
      {
        internalType: "address",
        name: "debtAsset",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "collateralAmount",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "debtRepayAmount",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "debtRateMode",
        type: "uint256",
      },
      {
        components: [
          {
            internalType: "uint256",
            name: "amount",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "deadline",
            type: "uint256",
          },
          {
            internalType: "uint8",
            name: "v",
            type: "uint8",
          },
          {
            internalType: "bytes32",
            name: "r",
            type: "bytes32",
          },
          {
            internalType: "bytes32",
            name: "s",
            type: "bytes32",
          },
        ],
        internalType: "struct IRepayWithCollateral.PermitSignature",
        name: "permitSignature",
        type: "tuple",
      },
      {
        internalType: "bool",
        name: "useEthPath",
        type: "bool",
      },
    ],
    name: "swapAndRepay",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
];
